import "./index.scss";
import { CircularProgress } from "@mui/material";

const GlobalSpinner = (): React.JSX.Element => {
	return (
		<div className="global-spinner">
			<CircularProgress />
		</div>
	);
};

export default GlobalSpinner;
